import { useTranslation } from 'react-i18next';

export default function FeaturesSettingTopTitle({
  title,
  description,
}: IProps) {
  const { t } = useTranslation();

  return (
    <div className="px-4 mt-4">
      <div className="text-xl text-backContrastColor">{title}</div>
      {description && (
        <div className="text-auxiliaryTextColor mt-2 mb-2">{description}</div>
      )}
    </div>
  );
}

interface IProps {
  //标题
  title: string;
  //描述
  description?: string;
}
