import { BasilSearchSolid } from '@/assets/icons/comm/BasilSearchSolid';
import BaseInput from '@/components/base/baseInput';
import { useTranslation } from 'react-i18next';

export default ({ value, changeValue }: IProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center pb-3 px-4 w-full sticky top-0 bg-background z-50">
      <div className="text-center flex-1">
        <BaseInput
          className="bg-backgroundAuxiliaryColor overflow-hidden mt-2 mb-3 h-[40px]"
          value={value}
          startContent={
            <div>
              <BasilSearchSolid />
            </div>
          }
          onChange={(e) => changeValue(e.target.value)}
          placeholder={t('搜索')}
          classNames={{
            base: 'rounded-md overflow-hidden h-[40px]',
            inputWrapper:
              'h-full border-none rounded-[30px]  group-data-[focus=true]:border-backgroundAuxiliaryColor',
          }}
        ></BaseInput>
      </div>
    </div>
  );
};

interface IProps {
  value: string;
  changeValue: (value: string) => void;
}
