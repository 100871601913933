import { IconParkSolidCorrect } from '@/assets/icons/comm/IconParkSolidCorrect';
import { history } from '@@/core/history';
import { useModel } from '@@/exports';
import { Image } from '@nextui-org/react';
import { useMemo } from 'react';

export default ({ searchKey }: IProps) => {
  const { exchange, setCurrentExchangeSetting, currentExchangeSetting } =
    useModel('exchange');

  const renderList = useMemo(() => {
    return exchange?.usdExchangeList?.filter?.((item: any) => {
      return (
        item.type === 1 &&
        item.exchange?.toLowerCase()?.includes(searchKey?.trim()?.toLowerCase())
      );
    });
  }, [exchange?.usdExchangeList, searchKey]);

  return (
    <>
      <div className="px-4">
        <div className="mt-2">
          {renderList?.map?.((item: any) => (
            <div
              key={item.id}
              onClick={() => {
                setCurrentExchangeSetting(item);
                setTimeout(() => {
                  history.back();
                }, 50);
              }}
            >
              <div className="flex  justify-between items-center text-base mb-8  text-backContrastColor">
                <div className="flex items-center">
                  <Image
                    className="w-7 h-7 rounded-full object-cover"
                    src={item.icon}
                  />
                  <div>
                    <div className="ml-3">{item?.exchange}</div>
                    {/* <div className="ml-3 text-auxiliaryTextColor text-xs">
                        {item?.symbol}
                      </div> */}
                  </div>
                </div>
                {currentExchangeSetting?.id === item?.id && (
                  <IconParkSolidCorrect className="text-primary" />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

interface IProps {
  searchKey: string;
}
