import BaseTopNav from '@/components/base/baseTopNav';
import FeaturesSettingTopTitle from '@/components/features/featuresSettingTopTitle';
import ExchangeList from '@/pages/mine/exchange/components/ExchangeList';
import ExchangeSerach from '@/pages/mine/exchange/components/ExchangeSerach';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const [key, setKey] = useState('');
  return (
    <>
      <BaseTopNav />
      {/*顶部标题*/}
      <FeaturesSettingTopTitle
        title={t('设置汇率')}
        description={t('选择您需要的法币计价基本单位')}
      />
      {/*搜索*/}
      <ExchangeSerach value={key} changeValue={setKey} />
      <ExchangeList searchKey={key} />
    </>
  );
};
